import React from 'react';
import imageKyc from 'styles/images/pages/dashboard/kyc.png';
import { Button, Row, updateQueryParams } from 'tera-dls';
import FrontCitizenIdCardSkeleton from 'styles/images/pages/KYC/front-citizen-id-card-skeleton.png';
import BackCitizenIdCardSkeleton from 'styles/images/pages/KYC/back-citizen-id-card-skeleton.png';
import { useNavigate } from 'react-router-dom';
import { MY_INFORMATION_URL } from 'pages/Account/MyInformation/url';

const KYC = () => {
  const navigate = useNavigate();

  const redirectKyc = () => {
    const paramString = updateQueryParams({
      tab: 'kyc',
    });
    navigate(`${MY_INFORMATION_URL.list.path}${paramString}`);
  };

  return (
    <Row className="grid-cols-6 gap-2.5 p-4 bg-white rounded-md shadow my-2.5">
      <img src={imageKyc} alt="kyc" />
      <div className="col-span-5">
        <Row className="grid-cols-2 gap-5">
          <img src={FrontCitizenIdCardSkeleton} alt="cmnd-mat-truoc" />
          <img src={BackCitizenIdCardSkeleton} alt="cmnd-mat-sau" />
        </Row>
        <div className="flex gap-2.5 items-center justify-between pt-8">
          <p>
            Quy trình xác minh danh tính của khách hàng, đảm bảo những khách
            hàng đăng ký đều là người thật.
          </p>
          <Button onClick={redirectKyc}>Đăng ký KYC</Button>
        </div>
      </div>
    </Row>
  );
};

export default KYC;
