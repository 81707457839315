import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const DatacenterAPIEndpoint = `${endpoint}/admin/datacenter`;

const DatacenterAPI = {
  getList: async ({ params }) =>
    await api
      .get(`${DatacenterAPIEndpoint}/list`, params)
      .then((res) => res.data?.data),
};

export default DatacenterAPI;
