import { useMutation, useQuery } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import CopyClipboardText from '_common/component/CopyClipboardText';
import IconText from '_common/component/IconText';
import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { SERVICE_OPTIONS } from '_common/constants/serviceManagement';
import _ from 'lodash';
import InvoiceAPI from 'pages/InvoiceManagement/Invoice/apis';
import {
  TABLE_STATUS_COLOR,
  TABLE_STATUS_TEXT,
} from 'pages/InvoiceManagement/Invoice/constants';
import { INVOICE_URL } from 'pages/InvoiceManagement/Invoice/url';
import { SHOPPING_CART_URL } from 'pages/ShoppingCart/url';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentMethodAPI from 'states/api/PaymentMethodAPI';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  CreditCardSolid,
  DocumentDuplicateOutlined,
  Spin,
  Tag,
  formatCurrency,
  formatDate,
  getQueryParams,
  notification,
  updateQueryParams,
} from 'tera-dls';

const PaymentPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const serviceType = useMemo(
    () => SERVICE_OPTIONS.find((service) => service.value === queryParams.cart),
    [queryParams.cart],
  );

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          {`Danh sách ${serviceType?.label}`}
        </span>
      ),
      onClick: () => navigate(serviceType?.listPath),
    },
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          {`Thêm ${serviceType?.label}`}
        </span>
      ),
      onClick: () => navigate(serviceType?.createPath),
    },
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          {`Thông tin thanh toán ${serviceType?.label}`}
        </span>
      ),
      onClick: () =>
        navigate(
          `${SHOPPING_CART_URL.cart.path}${updateQueryParams({
            ...queryParams,
          })}`,
        ),
    },
    {
      title: `Thanh toán ${serviceType?.label}`,
    },
  ];

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-cloud-vps-detail', queryParams.invoice_id],
    () => InvoiceAPI.getDetail({ id: queryParams.invoice_id }),
    {
      enabled: !_.isNil(queryParams.invoice_id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (queryParams.invoice_id) refetchDetail();
  }, [queryParams.invoice_id]);

  const {
    data: dataQR,
    refetch: refetchQR,
    isLoading: isLoadingQR,
    isError: isErrorQR,
  } = useQuery(
    ['get-payment-method-qr', dataDetail?.payment_method?.id],
    () =>
      PaymentMethodAPI.getVietQR({
        id: dataDetail?.payment_method?.id,
        params: {
          total: dataDetail?.final_total,
          addInfo: dataDetail?.invoice_no,
        },
      }),
    {
      enabled: !_.isNil(dataDetail?.payment_method?.id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (dataDetail?.payment_method?.id) refetchQR();
  }, [dataDetail?.payment_method?.id]);

  const details = [
    {
      label: 'Số tài khoản',
      value: dataDetail?.payment_method?.account_number,
    },
    {
      label: 'Người thụ hưởng',
      value: dataDetail?.payment_method?.account_name,
    },
    {
      label: 'Ngân hàng',
      value: `${dataDetail?.payment_method?.type.name} - ${dataDetail?.payment_method?.type.description}`,
    },
    {
      label: 'Nội dung',
      value: (
        <CopyClipboardText
          valueCopy={dataDetail?.invoice_no}
          tooltipPlacement="top"
        >
          <IconText
            suffix={
              <DocumentDuplicateOutlined className="size-6 text-blue-500" />
            }
          >
            {dataDetail?.invoice_no}
          </IconText>
        </CopyClipboardText>
      ),
    },
  ];

  const { mutate: mutatePay, isLoading: isLoadingPay } = useMutation(
    (params: any) => InvoiceAPI.pay({ id: params?.id, params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(`${INVOICE_URL.detail.path}/${dataDetail.id}`);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleConfirmPayment = () => {
    if (isLoadingPay) return;
    mutatePay({
      id: dataDetail?.id,
      payment_method_id: dataDetail?.payment_method_id,
    });
  };

  console.log(dataDetail?.payment_method);

  return (
    <Spin spinning={isLoadingPay}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={items[items.length - 2].onClick}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb separator={'/'} items={items} />
            </div>
          </div>
        </div>
        <Spin spinning={isLoadingDetail}>
          {isErrorDetail ? (
            <NoData />
          ) : (
            <div className="px-2">
              <CardDetail className="p-4 flex flex-col gap-4">
                <span className="text-blue-500">Thông tin thanh toán</span>
                <span>
                  Quý khách vui lòng điền đúng số hoá đơn và số tiền hoặc quét
                  mã QR để thanh toán được xác nhận nhanh chóng.
                </span>
                <div className="w-full flex flex-col items-center gap-4">
                  <CardDetail className="w-[432px] p-4 py-8 relative">
                    <div className="before:content-[''] before:bg-[url('styles/images/pages/Payment/background_payment.png')] before:bg-center before:bg-cover before:bg-no-repeat before:absolute before:top-0 before:right-0 before:bottom-0 before:left-0 before:opacity-[0.1] before:z-[-1]" />
                    <div className="flex flex-col gap-4 z-1">
                      <div className="w-full flex flex-col items-center gap-4">
                        <span className="text-[16px] font-bold">
                          Thanh toán ZingServer
                        </span>
                        {dataDetail?.payment_method?.type?.type === 'bank' ? (
                          <Spin spinning={isLoadingQR}>
                            {isErrorQR ? (
                              <img
                                src={dataDetail?.payment_method?.qr_code_url}
                                className="w-[90%] object-contain m-auto"
                                alt="qr-code"
                              />
                            ) : (
                              <img
                                src={dataQR?.data?.qrDataURL}
                                alt={dataQR?.data?.qrCode}
                              />
                            )}
                          </Spin>
                        ) : (
                          <img
                            src={dataDetail?.payment_method?.qr_code_url}
                            className="w-[90%] object-contain m-auto"
                            alt="qr-code"
                          />
                        )}
                      </div>
                      <div className="flex items-center gap-2">
                        <CreditCardSolid className="w-[30px] text-blue-500" />
                        <div className="text-[16px] flex flex-col gap-2">
                          <span>Thanh toán</span>
                          <CopyClipboardText
                            valueCopy="99000000"
                            className="text-green-500 font-bold tracking-widest flex"
                            tooltipPlacement="top"
                          >
                            <IconText
                              suffix={
                                <DocumentDuplicateOutlined className="size-6" />
                              }
                            >
                              {formatCurrency(dataDetail?.final_total)}
                            </IconText>
                          </CopyClipboardText>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="flex justify-between items-center">
                          <span className="text-gray-500">Trạng thái</span>
                          <Tag
                            color={TABLE_STATUS_COLOR[dataDetail?.status]}
                            className="font-medium"
                          >
                            {TABLE_STATUS_TEXT[dataDetail?.status]}
                          </Tag>
                        </div>
                        <div className="pb-4 border-b-[2px] border-dashed flex justify-between items-center">
                          <span className="text-gray-500">Thời gian</span>
                          <span>
                            {formatDate(
                              dataDetail?.created_at,
                              DATE_TIME_FORMAT,
                            )}
                          </span>
                        </div>
                        {dataDetail?.payment_method &&
                          details.map((detail, index) => (
                            <div
                              key={index}
                              className="flex justify-between items-center"
                            >
                              <span className="text-gray-500">
                                {detail.label}
                              </span>
                              <span>{detail.value}</span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </CardDetail>
                  <Button
                    className="w-[432px]"
                    onClick={handleConfirmPayment}
                    loading={isLoadingPay}
                  >
                    Xác nhận đã thanh toán
                  </Button>
                  <Button
                    className="w-[432px]"
                    onClick={() =>
                      navigate(`${INVOICE_URL.detail.path}/${dataDetail.id}`)
                    }
                  >
                    Xem hóa đơn
                  </Button>
                </div>
              </CardDetail>
            </div>
          )}
        </Spin>
      </div>
    </Spin>
  );
};

export default PaymentPage;
