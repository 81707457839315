import ZingIcon from '_common/component/ZingIcon';
import { SERVICE_CLOUD_SERVER_URL } from 'pages/ServiceManagement/CloudServer/url';
import { SERVICE_CLOUD_VPS_URL } from 'pages/ServiceManagement/CloudVPS/url';
import { SERVICE_DOMAIN_URL } from 'pages/ServiceManagement/Domain/url';
import { SERVICE_EMAIL_SECURITY_URL } from 'pages/ServiceManagement/EmailSecurity/url';
import { SERVICE_HOSTING_URL } from 'pages/ServiceManagement/Hosting/url';
import { SERVICE_SSL_URL } from 'pages/ServiceManagement/SSL/url';

export enum SERVICE {
  DOMAIN = 'domain',
  EMAIL = 'email',
  HOSTING = 'hosting',
  CLOUD_SERVER = 'cloud_server',
  SSL = 'ssl',
  CLOUD_VPS = 'cloud_vps',
}
export enum SERVICE_STATUS {
  INITIALIZING = 'Initializing',
  RUNNING = 'Running',
  BLOCK = 'Block',
}
export const PRICE_PERIOD_TEXT = {
  // daily: '1 ngày',
  // threeDays: '3 ngày',
  // weekly: '1 tuần',
  monthly: '1 tháng',
  twoMonths: '2 tháng',
  threeMonths: '3 tháng',
  sixMonths: '6 tháng',
  oneYear: '1 năm',
  twoYears: '2 năm',
  threeYears: '3 năm',
  fourYears: '4 năm',
  fiveYears: '5 năm',
};
export const PRICE_PERIOD_DAY = {
  daily: 1,
  threeDays: 3,
  weekly: 7,
  monthly: 30,
  twoMonths: 60,
  threeMonths: 90,
  sixMonths: 180,
  oneYear: 365,
  twoYears: 730,
  threeYears: 1095,
  fourYears: 1460,
  fiveYears: 1825,
};
export const SERVICE_TYPE_TEXT = {
  [SERVICE.DOMAIN]: 'Domain',
  [SERVICE.EMAIL]: 'Email và bảo mật',
  [SERVICE.HOSTING]: 'Web Hosting',
  [SERVICE.CLOUD_SERVER]: 'Cloud Server',
  [SERVICE.SSL]: 'SSL',
  [SERVICE.CLOUD_VPS]: 'Cloud VPS',
};
export const SERVICE_OPTIONS = [
  {
    value: SERVICE.DOMAIN,
    label: 'Tên miền',
    icon: <ZingIcon.IconMenuWorld />,
    listPath: SERVICE_DOMAIN_URL.list.path,
    createPath: SERVICE_DOMAIN_URL.create.path,
  },
  {
    value: SERVICE.EMAIL,
    label: 'Email và Bảo mật',
    icon: <ZingIcon.IconMenuMail />,
    listPath: SERVICE_EMAIL_SECURITY_URL.list.path,
    createPath: SERVICE_EMAIL_SECURITY_URL.create.path,
  },
  {
    value: SERVICE.HOSTING,
    label: 'Web Hosting',
    icon: <ZingIcon.IconMenuHosting />,
    listPath: SERVICE_HOSTING_URL.list.path,
    createPath: SERVICE_HOSTING_URL.create.path,
  },
  {
    value: SERVICE.CLOUD_SERVER,
    label: 'Cloud Server',
    icon: <ZingIcon.IconMenuDatabase />,
    listPath: SERVICE_CLOUD_SERVER_URL.list.path,
    createPath: SERVICE_CLOUD_SERVER_URL.create.path,
  },
  {
    value: SERVICE.SSL,
    label: 'Chứng chỉ SSL',
    icon: <ZingIcon.IconMenuLock />,
    listPath: SERVICE_SSL_URL.list.path,
    createPath: SERVICE_SSL_URL.create.path,
  },
  {
    value: SERVICE.CLOUD_VPS,
    label: 'Cloud VPS',
    icon: <ZingIcon.IconMenuCloud />,
    listPath: SERVICE_CLOUD_VPS_URL.list.path,
    createPath: SERVICE_CLOUD_VPS_URL.create.path,
  },
];
