import PageTitle from '_common/component/PageTitle';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react/jsx-runtime';
import Footer from './components/Footer';
import HighQualityProxy from './components/HighQualityProxy';
import KYC from './components/KYC';
import VPSServices from './components/VPSServices';
import WidgetOverview from './components/WidgetOverview';

const Dashboard = (): JSX.Element => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <Fragment>
      <PageTitle title="TỔNG QUAN" />
      <div className="tera-page flex flex-col items-center">
        <div className="w-[1000px]">
          <WidgetOverview />
          {!user?.account?.kyc && <KYC />}
          <VPSServices />
          <HighQualityProxy />
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};

export default observer(Dashboard);
