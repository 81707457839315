import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { messageValidate, messageWarning } from '_common/constants/message';
import {
  PRICE_PERIOD_DAY,
  PRICE_PERIOD_TEXT,
} from '_common/constants/serviceManagement';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { INVOICE_URL } from 'pages/InvoiceManagement/Invoice/url';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ServiceAPI from 'states/api/ServiceAPI';
import { Modal, Spin, formatCurrency, notification } from 'tera-dls';

interface IModalExtendProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalExtend = ({ open, close, id }: IModalExtendProps): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { ip: '', period: '' },
  });
  const { isDirty } = form.formState;
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-cloud-vps-detail', id],
    () => ServiceAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(dataDetail))
      form.reset({
        ip: dataDetail?.ip,
      });
  }, [dataDetail]);

  const PeriodOptions = useMemo(
    () =>
      Object.entries(PRICE_PERIOD_TEXT).map(([key, value]) => ({
        value: key,
        label: value,
      })),
    [form.getValues()],
  );
  const price = useMemo(
    () => Number(dataDetail?.product.prices[form.watch('period')]),
    [form.getValues()],
  );
  const priceVAT = useMemo(
    () => Number(dataDetail?.product.prices[form.watch('period')] * 0.08),
    [form.getValues()],
  );

  const { mutate: mutateManualRenew, isLoading: isLoadingManualRenew } =
    useMutation(
      (params: any) =>
        ServiceAPI.manualRenew({ id: params.id, params: params.params }),
      {
        onSuccess: (res) => {
          if (_.isEqual(res?.code, 200)) {
            queryClient.invalidateQueries(['get-service-cloud-vps-list']);
            queryClient.invalidateQueries(['get-service-cloud-vps-detail']);
            notification.success({
              message: res?.msg,
            });
            navigate(`${INVOICE_URL.detail.path}/${res?.data?.id}`);

            close();
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleManualRenew = (values: any) =>
    confirm.warning({
      title: 'Xác nhận gia hạn dịch vụ',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn gia hạn dịch vụ</p>
          <p>
            <b>{dataDetail?.product.planCode}</b> này không?
          </p>
        </div>
      ),
      onOk: () =>
        mutateManualRenew({
          id: dataDetail?.id,
          params: {
            period_text: PRICE_PERIOD_TEXT[values.period],
            period_days: PRICE_PERIOD_DAY[values.period],
            price: price,
          },
        }),
    });

  const handleCloseConfirm = () =>
    isDirty
      ? confirm.warning({
          title: 'Thoát bản ghi',
          content: (
            <>
              <p>{messageWarning.WARNING_EXIT_1}</p>
              <p>{messageWarning.WARNING_EXIT_2}</p>
            </>
          ),
          onOk: () => close(),
        })
      : close();

  return (
    <Modal
      title="Gia hạn VPS"
      open={open}
      centered={true}
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={form.handleSubmit(handleManualRenew)}
      onCancel={handleCloseConfirm}
      className="md:w-[500px]"
      bodyClassName="!py-4"
      confirmLoading={isLoadingDetail || isLoadingManualRenew}
    >
      <Spin spinning={isLoadingDetail || isLoadingManualRenew}>
        {isErrorDetail ? (
          <NoData />
        ) : (
          <FormTera form={form} className="flex flex-col gap-2">
            <FormTeraItem label="IP" name="ip" className="mb-0">
              <Input disabled />
            </FormTeraItem>
            <FormTeraItem
              label="Mã giảm giá"
              name="promo_code"
              className="mb-0"
            >
              <Input placeholder="Vui lòng nhập (Nếu có)" />
            </FormTeraItem>
            <FormTeraItem
              label="Thời hạn"
              name="period"
              className="mb-0"
              rules={[{ required: messageValidate.emptySelect }]}
            >
              <Select options={PeriodOptions} />
            </FormTeraItem>
            <FormTeraItem label="Thành tiền" name="price" className="mb-0">
              <Input value={formatCurrency(price + priceVAT)} disabled />
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
};

export default observer(ModalExtend);
