import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { SERVICE, SERVICE_OPTIONS } from '_common/constants/serviceManagement';
import FormTera from '_common/dof/FormTera';
import { useStores } from '_common/hooks';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import OrderAPI from 'states/api/OrderAPI';
import PaymentMethodAPI from 'states/api/PaymentMethodAPI';
import {
  Spin,
  getQueryParams,
  notification,
  updateQueryParams,
} from 'tera-dls';
import Advertisement from './components/Advertisement';
import CustomerInformation from './components/CustomerInformation';
import Header from './components/Header';
import Note from './components/Note';
import Notification from './components/Notification';
import OrderInformation from './components/OrderInformation';
import Payment from './components/Payment';
import PaymentMethod from './components/PaymentMethod';
import PromoCode from './components/PromoCode';
import { SHOPPING_CART_URL } from './url';

const ShoppingCartPage = (): JSX.Element => {
  const {
    shoppingCartStore: { cart, updateCart },
  } = useStores();

  const { data: dataPaymentMethodList } = useQuery(
    ['get-finance-payment-method-list-system'],
    () => {
      return PaymentMethodAPI.getListOfSystem({ params: {} });
    },
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      note: '',
      promo_code: '',
      payment_method_id: null,
      is_ok: false,
    },
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const serviceInCart = useMemo(() => cart?.[queryParams.cart], [cart]);
  const serviceType = useMemo(
    () => SERVICE_OPTIONS.find((service) => service.value === queryParams.cart),
    [queryParams.cart],
  );

  const { mutate: mutateCreateOrder, isLoading: isLoadingCreateOrder } =
    useMutation((params: any) => OrderAPI.create({ params }), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });

          if (res.data.payment_method_id == 0) {
            queryClient.invalidateQueries(['get-profile']);
            return navigate(serviceType?.listPath);
          }
          navigate(
            `${SHOPPING_CART_URL.payment.path}${updateQueryParams({
              ...queryParams,
              invoice_id: res.data.id,
            })}`,
          );
          updateCart(queryParams.cart, {});
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    });

  const handleSubmitForm = (values: any) => {
    if (isLoadingCreateOrder) return;

    if (_.isEqual(serviceInCart?.type, SERVICE.DOMAIN)) {
      mutateCreateOrder({
        ...values,
        type: serviceInCart?.type,
        name: serviceInCart?.name,
        label: serviceInCart?.label,
        period: serviceInCart?.period,
        period_text: serviceInCart?.period_text,
        first_payment: serviceInCart?.first_payment,
        recurring_amount: serviceInCart?.recurring_amount,
        idprotection: serviceInCart?.idprotection,
        autorenew: serviceInCart?.autorenew,
      });
    } else {
      mutateCreateOrder({
        ...values,
        type: serviceInCart?.type,
        datacenterId: serviceInCart?.datacenterId,
        planId: serviceInCart?.planId,
        locationId: serviceInCart?.locationId,
        osId: serviceInCart?.osId,
        period: serviceInCart?.period,
        period_days: serviceInCart?.period_days,
        period_text: serviceInCart?.period_text,
        first_payment: serviceInCart?.first_payment,
        isAutoRenew: serviceInCart?.isAutoRenew,
        userName: serviceInCart?.userName,
      });
    }
  };

  useEffect(() => {
    if (dataPaymentMethodList && !form.watch('payment_method_id')) {
      form.setValue('payment_method_id', dataPaymentMethodList?.data?.[0]?.id);
    }
  }, [dataPaymentMethodList]);

  return (
    <Spin spinning={isLoadingCreateOrder}>
      <div className="tera-page-form">
        <Header />
        {!_.isNil(queryParams.cart) && _.isEmpty(serviceInCart) ? (
          <NoData />
        ) : (
          <FormTera form={form} className="w-full p-2 flex items-start gap-8">
            <div className="w-[70%] p-4 bg-white rounded-[6px] drop-shadow">
              <OrderInformation />
              <Notification />
              <CustomerInformation />
              <Advertisement />
              <Note />
            </div>
            <div className="w-[30%] flex flex-col gap-8">
              <PromoCode />
              <PaymentMethod />
              <Payment form={form} handleSubmitForm={handleSubmitForm} />
            </div>
          </FormTera>
        )}
      </div>
    </Spin>
  );
};

export default ShoppingCartPage;
